
import { Options, Vue } from "vue-class-component";
import Swapper from '@/components/Swapper.vue';
import Tokenomics from '@/components/Tokenomics.vue';
import store from "@/store";

@Options({
  components: {
    Swapper,
    Tokenomics
  },
  props: {
  }
})
export default class MainSection extends Vue {

  store: any;
  bet = {
    title: 'Who will win?',
    options: [
      {
        letter: 'A',
        option: 'Chelsea'
      },
      {
        letter: 'B',
        option: 'Liverpool'
      },
      {
        letter: 'C',
        option: 'Barcelona'
      }
    ]
  };

  balance = 0.0;

  beforeMount () {
    this.store = store;
  }

  getReward () {
    this.balance += Math.random() * 100;
  }

}
