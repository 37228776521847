
import { Options, Vue } from "vue-class-component";
import Header from "@/components/Header.vue";
import MainSection from "@/components/MainSection.vue";

@Options({
  components: {
    Header,
    MainSection
  },
})
export default class Home extends Vue {}
