
import store from "@/store";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
  },
  watch: {
    amount: function (val) {

      if (typeof val != "number" || val < 0) {
        this.amount = 0;
        this.tokens = 0;
      } else {
        this.tokens = val / 0.001;
      }

    }
  }
})
export default class Swapper extends Vue {

  amount = null;
  tokens = 0;

  async swap () {

    if (Object.keys(store.state.account).length == 0) {
      store.dispatch('connectWallet');
    } else {
      let session = store.state.session;
      
    }

  }

}
