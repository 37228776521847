
import { Options, Vue } from "vue-class-component";
import WalletButton from "@/components/WalletButton.vue";

@Options({
  components: {
    WalletButton
  },
  props: {
  },
})
export default class Header extends Vue {

}
