
import { Options, Vue } from "vue-class-component";
import store from "@/store";

@Options({
  props: {
  },
})
export default class WalletButton extends Vue {

  connected = false;
  avatar = "";
  name = "";
  link = {
  };
  store = {};

  beforeMount () {
    this.store = store;
  }

  async connect () {

    await store.dispatch('connectWallet').then((data) => {
      this.connected = true;
      this.avatar = store.state.account.avatar;
      this.name = store.state.account.name;
    });

  }

  async disconnect () {
    this.connected = false;
  }

}
