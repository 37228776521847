import { ConnectWallet } from "@protonprotocol/proton-web-sdk";
import { createStore } from "vuex";

export default createStore({
  state: {
    connected: false,
    loading: false,
    account: {
      avatar: "",
      name: ""
    },
    session: {

    }
  },
  mutations: {
    SET_CONNECTED_STATUS (state, status) {
      state.connected = status;
    },
    SET_LOADING_STATUS (state, status) {
      state.loading = status;
    },
    SET_ACCOUNT (state, account) {
      state.account = account;
    },
    SET_SESSION (state, session) {
      state.session = session;
    }
  },
  actions: {
    async connectWallet (context) {

      context.commit('SET_LOADING_STATUS', true);

      const appIdentifier = 'bether.gm' // Proton account name

      // Pop up modal
      const link = await ConnectWallet({
          linkOptions: {
              endpoints: ['https://proton.greymass.com'],
              //restoreSession: true
          },
          transportOptions: {
              requestAccount: 'bether.gm', /* Optional: Your proton account */
              requestStatus: true, /* Optional: Display request success and error messages, Default true */
          },
          selectorOptions: {
              appName: 'Betherance', /* Optional: Name to show in modal, Default 'app' */
              appLogo: 'https://i.ibb.co/4TZSs5Z/bether-logo.png', /* Optional: Logo to show in modal */
              customStyleOptions: { /* Optional: Custom style options for modal */
                  modalBackgroundColor: '#2b2b2b',
                  logoBackgroundColor: '#2b2b2b',
                  isLogoRound: true,
                  optionBackgroundColor: '#2b2b2b',
                  optionFontColor: 'white',
                  primaryFontColor: 'white',
                  secondaryFontColor: '#6B727F',
                  linkColor: '#ffcc00'
              }
              // walletType: 'proton' /* Optional: Connect to only specified wallet (e.g. 'proton', 'anchor') */
          }
      }).then((data: any) => {

        context.commit('SET_LOADING_STATUS', false);
        context.commit('SET_CONNECTED_STATUS', true);
        context.commit('SET_ACCOUNT', data.session.accountData.shift());
        context.commit('SET_SESSION', data.session);

      });

    }
  },
  modules: {},
});
