import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ab2ad19"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.store.state.connected)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "wallet-btn",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.connect()))
        }, "Connect wallet"))
      : _createCommentVNode("", true),
    (_ctx.store.state.connected)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("p", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.disconnect()))
          }, "Disconnect"),
          _createElementVNode("img", {
            class: "avatar",
            src: 'data:image/png;base64,' + _ctx.store.state.account.avatar
          }, null, 8, _hoisted_1)
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}